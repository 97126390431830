import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { webApiUrl } from '@domains/shared/domain/utils/url';
import { headers } from '@domains/shared/domain/utils/global-constants';
import { StudentCompanyCourseDTO } from '../../models/student/student-company-course.interface';
import { StudentCompanyCourseDetailDTO } from '../../models/student/student-company-course-detail.interface';
import { StudentCourseTreeDTO } from '../../models/student/student-course-tree.interface';


@Injectable({ providedIn: 'root' })
export class StudentCompanyCourseService {

  /*
  * TODO: Backend now reflects company course for student as course
  */

  private baseUrl =  `${webApiUrl}/student/Course`;

  private baseUrlV2 =  `${webApiUrl}/v2/student/Course`;

  constructor(private http: HttpClient) {}

  getCourses(): Observable<StudentCompanyCourseDTO[]> {
    const url = [this.baseUrl].join('/');
    return this.http.get<StudentCompanyCourseDTO[]>(url, { headers });
  }

  getCourse(courseId: string): Observable<StudentCompanyCourseDetailDTO> {
    const url = [this.baseUrlV2, courseId].join('/');
    return this.http.get<StudentCompanyCourseDetailDTO>(url, { headers });
  }

  getCourseTree(courseId: string): Observable<StudentCourseTreeDTO> {
    const url = [this.baseUrlV2, courseId, 'Tree'].join('/');
    return this.http.get<StudentCourseTreeDTO>(url, { headers });
  }
}
