/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { NotificationService } from '@domains/shared/domain/application-service/notification.service';
import { REDIRECTPAGE } from '@domains/shared/domain/utils/global-constants';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';


// Note: these are only the initial imports. Add more as needed.

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(public notify: NzMessageService, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response.status === 500) {
          this.notify.error( 'E500 - Na serveru se vyskytla chyba!');
          console.log(response)
        }

        if (response.status === 401) {
          console.log(response);
          this.notify.error( 'Přihlášení vypršelo, přesměrovávám na login. Prosím přihlašte se znovu.');
          this.router.navigate([REDIRECTPAGE.LOGIN_PAGE]);
        }
        return throwError(response);
      })
    );
  }
}
