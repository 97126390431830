import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'elearning';

  constructor(private translate: TranslateService) {
    this.setTranslate();
  }

  private setTranslate() {
    this.translate.addLangs(['en', 'cs', 'de']);
    this.translate.setDefaultLang('cs');

    const browserLang = this.translate.getBrowserLang();
    this.translate.use('cs');
  }
}
