import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { StudentCompanyCourseFacade } from '@domains/course/domain/application-services/student-company-course.facade';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { noop } from 'rxjs';

@Component({
  selector: 'app-bp-collapse',
  templateUrl: './bp-collapse.component.html',
  styleUrls: ['./bp-collapse.component.scss'],
  standalone: true,
  imports: [CommonModule, NzCollapseModule]
})
export class BpCollapseComponent {

  @Input() header: string = '';

  @Input() content: string = '';

  @Input() expanded: boolean = false;

  @Input() sectionId: string = '';

  constructor(private courseFacade: StudentCompanyCourseFacade) { }

  onExpandClick(): void {
    this.expanded = !this.expanded;
  }

  trackCollapseExpand(): void {
    if(!this.sectionId) {
      return;
    }
    this.courseFacade.registerClickSection(this.sectionId).subscribe({
      next: noop
    })
  }
}
