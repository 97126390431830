import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { headers } from '@domains/shared/domain/utils/global-constants';
import { webApiUrl } from '@domains/shared/domain/utils/url';
import { Observable } from 'rxjs';
import { UserDetailBackofficeModel } from '../../models/user-detail-backoffice.model';

@Injectable({
  providedIn: 'root'
})
export class BackofficeUserService {

  private baseUrl = `${webApiUrl}/backoffice/User`;

  constructor(
    private http: HttpClient,
  ) { }

  sendRegisterEmail(userId: string, url: string = `${this.baseUrl}/${userId}/reinvitation`): Observable<any> {
    return this.http.post(url, {}, { headers });
  }

  canEmailBeUsedToCreateNewAccount(email: string, url: string = `${this.baseUrl}/Check/${email}/`): Observable<boolean> {
    return this.http.get<boolean>(url, { headers });
  }

  getUser(userId: string): Observable<UserDetailBackofficeModel> {
    const url = `${this.baseUrl}/${userId}`;
    return this.http.get<UserDetailBackofficeModel>(url, { headers });
  }

  patch(entity: any, id: string, url: string = `${this.baseUrl}/${id}`): Observable<any> {
    return this.http.patch<any>(url, entity, { headers });
  }
}
