import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { headers } from '@domains/shared/domain/utils/global-constants';
import { webApiUrl } from '@domains/shared/domain/utils/url';
import { Observable } from 'rxjs';
import { UserDetailBackofficeModel } from '../../models/user-detail-backoffice.model';

@Injectable({
  providedIn: 'root'
})
export class BackofficeStudentService {

  private baseUrl = `${webApiUrl}/backoffice/Student`;

  constructor(
    private http: HttpClient,
  ) { }

  //TODO: model StudentModel
  update(course: any, url: string = `${this.baseUrl}/${course.id}`): Observable<any> {
    return this.http.put(url, course, { headers });
  }

}
