import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  headers,
  localStorageUser,
} from '@domains/shared/domain/utils/global-constants';
import { webApiUrl } from '@domains/shared/domain/utils/url';
import { Observable, of, tap } from 'rxjs';
import { UserLogin } from '../../models/user-login.interface';
import { UserDTO } from '../../models/users.dto';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private baseUrl = `${webApiUrl}/Auth`;

  constructor(private http: HttpClient) { }

  // FIXME: needs to get data for user from localStorage
  getUserInfo(): Observable<UserDTO | undefined> {
    const url = [this.baseUrl, 'UserInfo'].join('/');
    return this.http.get<UserDTO>(url);
    //const user = localStorage.getItem(localStorageUser);
    // if (user) {
    //   const userInfo: UserDTO = JSON.parse(user);
    //   return of(userInfo);
    // } else {
    //   return of(undefined);
    // }
  }

  loginUser(data: UserLogin): Observable<UserDTO> {
    const url = [this.baseUrl, 'Login'].join('/');
    return this.http.post<UserDTO>(url, data);
  }

  //FIXME: remove after removing localstorage
  logoutUser(): Observable<undefined> {
    // const url = [this.baseUrl, 'Logout'].join('/');
    // return this.http.post<undefined>(url, {});

    if (localStorage.getItem(localStorageUser)) {
      localStorage.removeItem(localStorageUser);
    }

    // We dont have an api for logout
    // Until then we will just erase localStorage
    return of(undefined);
  }

  changeCompany(
    companyId: string,
    url: string = `${webApiUrl}/User/CurrentCompany/${companyId}`
  ): Observable<UserDTO> {
    return this.http.put<UserDTO>(url, null, { headers });
  }

  resetPassword(data: any): Observable<any> {
    const url = [this.baseUrl, 'Reset-password'].join('/');
    return this.http.post<any>(url, data);
  }

  newPassword(data: any): Observable<any> {
    const url = [this.baseUrl, 'New-password'].join('/');
    return this.http.post<any>(url, data);
  }

  checkToken(token: string): Observable<any> {
    const url = [this.baseUrl, 'CheckToken'].join('/');
    return this.http.post<any>(url, { token: token });
  }

  confirmEmail(data: any): Observable<any> {
    const url = [this.baseUrl, 'ConfirmEmail'].join('/');
    return this.http.post<any>(url, data);
  }

  activateAccount(data: any): Observable<any> {
    const url = [this.baseUrl, 'Activate-account'].join('/');
    return this.http.post<any>(url, data);
  }

  registration(data: any): Observable<any> {
    const url = [this.baseUrl, 'Registration'].join('/');
    return this.http.post<any>(url, data);
  }

  // forgottenPassword(email: string): Observable<undefined> {
  //   const url = [this.baseUrl, 'ForgottenPassword'].join('/');
  //   return this.http.post<undefined>(url, { emailAddress: email });
  // }

  // resetPassword(data: UserResetPassword): Observable<undefined> {
  //   const url = [this.baseUrl, 'NewPassword'].join('/');
  //   return this.http.post<undefined>(url, data);
  // }



  // confirmEmail(token: string): Observable<undefined> {
  //   const url = [this.baseUrl, 'ConfirmEmail'].join('/');
  //   return this.http.post<undefined>(url, { token: token });
  // }
}
