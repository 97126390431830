import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, finalize, forkJoin, map, Observable } from 'rxjs';
import { UserDTO } from '../models/users.dto';
import { NgxPermissionsService } from 'ngx-permissions';
import { AccountRoleEnum } from '../models/enums/account-roles.enum';
import { BackofficeCompanyDetailDTO } from '../models/company-backoffice-detail.interface';
import { BackofficeUserService } from '../infrastructure/backoffice/backoffice-user.service';

@Injectable({ providedIn: 'root' })
export class IdentityFacade {


  private getLoggedUserSubject = new BehaviorSubject<UserDTO | undefined>(undefined);
  public getLoggedUser$ = this.getLoggedUserSubject.asObservable().pipe(finalize(() => this.setPermissions()));
  private getCurrentCompanySubject = new BehaviorSubject<BackofficeCompanyDetailDTO | undefined>(undefined);
  public getCurrentCompany$ = this.getCurrentCompanySubject.asObservable().pipe(finalize(() => this.setPermissions()));

  constructor(
    private permissionService: NgxPermissionsService,
    private backofficeUserService: BackofficeUserService) {
    this.setPermissions();
  }

  public setCurrentCompany(company: BackofficeCompanyDetailDTO): void {
    this.getCurrentCompanySubject.next(company);
  }

  public setCurrentUser(user?: UserDTO): void {
    this.getLoggedUserSubject.next(user);
  }

  public getCurrentUser(): UserDTO | undefined {
    return this.getLoggedUserSubject.getValue();
  }

  private setPermissions(): void {
    combineLatest([this.getLoggedUser$, this.getCurrentCompany$]).subscribe({
      next: (data) => {
        this.permissionService.flushPermissions();

        const user = data[0];
        const company = data[1];

        if (user) {
          const perms = user.assignedRoles.map(x => x.name);
          this.permissionService.loadPermissions(perms);
        }

        // if(user?.isAdmin) {
        //   this.permissionService.addPermission(AccountRoleEnum.Admin);
        // }
      }
    })
  }

  canEmailBeUsedToCreateNewAccount(email: string): Observable<boolean> {
    return this.backofficeUserService.canEmailBeUsedToCreateNewAccount(email);
  }

}
