import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { Invitation } from '../models/invitation.interface';
//import { EntityList } from '@shared/model/api-get-interface';
import { CreateInvitation } from '../models/create-invitation.dto';
import { IdentityFacade } from '../application-services/identity.facade';


@Injectable({ providedIn: 'root' })
export class InviteService {

  baseUrl = `/api/v1/Invite`;

  constructor(private http: HttpClient, private identityFacade: IdentityFacade) {
  }

  inviteUser(data: CreateInvitation): Observable<undefined> {
    return this.identityFacade.getCurrentCompany$.pipe(
      switchMap((company) => {
        const url = `/api/v1/Company/${company?.id}/Invite`;
        return this.http.post<undefined>(url, data);
      })
    );
  }

  checkUser(token: string | null): Observable<Invitation> {
    const url = [this.baseUrl, token].join('/');
    return this.http.get<Invitation>(url);
  }

  // getInvitations(): Observable<EntityList<Invitation>> {
  //   const url = [this.baseUrl].join('/');
  //   return this.http.get<EntityList<Invitation>>(url);
  // }

  acceptInvitation(inviteId: string): Observable<undefined> {
    const url = [this.baseUrl, inviteId, 'accept'].join('/');
    return this.http.post<undefined>(url, {});
  }

  rejectInvitation(inviteId: string): Observable<undefined> {
    const url = [this.baseUrl, inviteId, 'reject'].join('/');
    return this.http.post<undefined>(url, {});
  }

  deleteInvitation(inviteId: string): Observable<undefined> {
    const url = [this.baseUrl, inviteId].join('/');
    return this.http.delete<undefined>(url, {});
  }

  acceptAll(): Observable<undefined> {
    const url = [this.baseUrl, 'acceptAll'].join('/');
    return this.http.post<undefined>(url, {});
  }

}
