import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { webApiUrl } from '@domains/shared/domain/utils/url';
import { headers } from '@domains/shared/domain/utils/global-constants';
import { StudentCourseArticleDTO } from '../../models/student/student-course-article.interface';
import { StudentCourseArticleCreateFeedbackDTO } from '../../models/student/student-course-article-create-feedback.interface';
import { StudentCourseArticleCreateAnswerDTO } from '../../models/student/student-course-article-create-answer.interface';


@Injectable()
export class StudentCompanyCourseArticleService {

  private baseUrl =  `${webApiUrl}/student/Article`;

  constructor(private http: HttpClient) {}

  getArticle(articleId: string): Observable<StudentCourseArticleDTO> {
    const url = [this.baseUrl, articleId].join('/');
    return this.http.get<StudentCourseArticleDTO>(url, { headers });
  }

  createFeedback(articleId: string, data: StudentCourseArticleCreateFeedbackDTO): Observable<void> {
    const url = [this.baseUrl, articleId, 'feedback'].join('/');
    return this.http.post<void>(url, data, { headers });
  }

  createStatistics(articleId: string): Observable<void> {
    const url = [this.baseUrl, articleId, 'statistics'].join('/');
    return this.http.post<void>(url, {}, { headers });
  }

  createAnswers(articleId: string, data: StudentCourseArticleCreateAnswerDTO[]): Observable<void> {
    const url = [this.baseUrl, articleId, 'answers'].join('/');
    return this.http.post<void>(url, data, { headers });
  }

  clearAnswers(articleId: string): Observable<void> {
    const url = [this.baseUrl, articleId, 'answers'].join('/');
    return this.http.delete<void>(url, { headers });
  }

  registerClickSection(articleSectionId: string): Observable<void> {
    const url = [`${webApiUrl}/student/ArticleSection`, articleSectionId].join('/');
    return this.http.post<void>(url, {}, { headers });
  }
}
