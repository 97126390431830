import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
// import { EntityList } from '@shared/model/api-get-interface';
import { CompanyPreviewDTO } from '../../models/company-preview-dto.interface';
import { UsersPreviewDTO } from '../../models/users-preview.dto';
import { UserDTO } from '../../models/users.dto';
import { IdentityFacade } from '../../application-services/identity.facade';
import { webApiUrl } from '@domains/shared/domain/utils/url';
import { headers, localStorageUser } from '@domains/shared/domain/utils/global-constants';
import { BackofficeCompanyDetailDTO } from '../../models/company-backoffice-detail.interface';
import { CompanyCourseModel } from '../../models/company-course.model';
import { UserFormModel } from '../../models/user-form.model';
import { BackofficeCompanyCourseDTO } from '@domains/course/domain/models/company-course.interface';


@Injectable({ providedIn: 'root' })
export class BackofficeCompanyService {

  private baseUrl = `${webApiUrl}/backoffice/Company`;

  constructor(
    private http: HttpClient,
    private identityFacade: IdentityFacade) {
  }

  updateCompany(company: BackofficeCompanyDetailDTO): Observable<BackofficeCompanyDetailDTO> {
    const url = `${this.baseUrl}/${company.id}`;

    return this.http.put<BackofficeCompanyDetailDTO>(url, company, { headers });
  }

  getCompanyCourses(companyId: string): Observable<BackofficeCompanyCourseDTO[]> {
    const url = `${this.baseUrl}/${companyId}/course`;
    return this.http.get<BackofficeCompanyCourseDTO[]>(url, { headers });
  }



  // getCompanies(): Observable<EntityList<CompanyPreviewDTO>> {
  //   const url = [this.baseCompanyUrl].join('/');
  //   return this.http.get<EntityList<CompanyPreviewDTO>>(url, {});
  // }


  // getUsers(params: HttpParams): Observable<EntityList<UsersPreviewDTO>> {
  //   return this.identityFacade.getCurrentCompany$.pipe(
  //     switchMap((company) => {
  //       const url = [this.baseCompanyUrl, company?.id, 'Account'].join('/');
  //       return this.http.get<EntityList<UsersPreviewDTO>>(url, { params});
  //     })
  //   );
  // }

  getUsers(companyId: string): Observable<UserDTO[]> {
    const url = `${this.baseUrl}/${companyId}/user`;
    return this.http.get<UserDTO[]>(url, { headers });
  }



  createUser(companyId: string, user: UserFormModel): Observable<UserDTO> {
    const url = `${this.baseUrl}/${companyId}/user`;
    return this.http.post<UserDTO>(url, user, { headers });
  }

  createUsers(companyId: string, users: UserFormModel[]): Observable<UserDTO[]> {
    const url = `${this.baseUrl}/${companyId}/users`;
    return this.http.post<UserDTO[]>(url, users, { headers });
  }

  getCompany(id: string): Observable<BackofficeCompanyDetailDTO> {
    const url = [this.baseUrl, id].join('/');
    return this.http.get<BackofficeCompanyDetailDTO>(url, { headers });
  }

  //TODO: Do it in companyCourseFacade
  updateEmail(course: BackofficeCompanyCourseDTO) {
    const url = `${webApiUrl}/backoffice/CompanyCourse/${course.id}/Email`;
    return this.http.put<CompanyCourseModel>(url, course, { headers: headers });
  }
}
