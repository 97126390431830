import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-loader-overlay',
  templateUrl: './page-loader-overlay.component.html',
  styleUrls: ['./page-loader-overlay.component.scss'],
  standalone: true
})
export class PageLoaderOverlayComponent {

  constructor() { }



}
