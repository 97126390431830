import { Injectable } from '@angular/core';
import { concatMap, finalize, map, mergeMap, Observable, of, switchMap } from 'rxjs';
import { UserLogin } from '../models/user-login.interface';
import { CompanyPreviewDTO } from '../models/company-preview-dto.interface';
import { UserDTO } from '../models/users.dto';
import { BackofficeCompanyFacade } from './backoffice-company.facade';
import { AuthFacade } from './auth.facade';
import { BackofficeCompanyDetailDTO } from '../models/company-backoffice-detail.interface';

@Injectable({ providedIn: 'root' })
export class AuthorizationFacade {

  constructor(
    private authFacade: AuthFacade,
    private companyFacade: BackofficeCompanyFacade) { }

  changeCompany(companyId: string): Observable<BackofficeCompanyDetailDTO> {
    return this.authFacade.changeCompany(companyId);
  }


  loadDefaultContext(): Observable<any> {

    return this.authFacade.loadUserIntoIdentity()
      .pipe(
        switchMap((user) => {
          if (user && user.id !== '00000000-0000-0000-0000-000000000000' && !user.assignedRoles.find(x => x.name === 'Student')) {
            return this.companyFacade.loadCompanyInfoIntoIdentity(user.currentCompanyId);
          }

          return of(user);

          // return of(user);
          // const url = window.location.pathname;
          // const isAdminArea = url.includes('admin');

          // if(!user.isAuthenticated){
          //   return of(null);
          // }

          // if(isAdminArea) {
          //   return this.companyFacade.loadCompanyInfoIntoIdentity(user.defaultCompanyId);
          // }

          // if(url.includes('/company/') && url.split('/company/').length > 1){
          //   const companyId = url.split('/company/')[1].split('/')[0];
          //   return this.companyFacade.loadCompanyInfoIntoIdentity(companyId);
          // }

          // return this.companyFacade.loadCompanyInfoIntoIdentity(user.defaultCompanyId);
        })
      );
  }

  loginIntoIdentity(data: UserLogin): Observable<UserDTO | undefined> {
    return this.authFacade.loginUser(data)
      .pipe(
        switchMap((user) => {
          if (!user!.assignedRoles.find(x => x.name === 'Student')) {
            return this.companyFacade.loadCompanyInfoIntoIdentity(user?.currentCompanyId!).pipe(map(() => user))
          }
          return of(user);
        }),
      );
  }

  logoutUser(): Observable<undefined> {
    return this.authFacade.logoutUser();
  }
}
