import { Injectable } from '@angular/core';
import { finalize, noop, Observable, of, switchMap, tap } from 'rxjs';
import { AccountService } from '../infrastructure/account.service';
import { UserDTO } from '../models/users.dto';
import { UserRegister } from '../models/user-register.interface';
import { UserResetPassword } from '../models/user-reset-password.interface';
import { IdentityFacade } from './identity.facade';
import { UserLogin } from '../models/user-login.interface';
import { localStorageUser } from '@domains/shared/domain/utils/global-constants';
import { AuthService } from '../infrastructure/system/auth.service';
import { BackofficeCompanyFacade } from './backoffice-company.facade';
import { BackofficeCompanyService } from '../infrastructure/backoffice/backoffice-company.service';
import { BackofficeCompanyDetailDTO } from '../models/company-backoffice-detail.interface';

// Account Facade was renamed into AuthFacade
@Injectable({ providedIn: 'root' })
export class AuthFacade {
  constructor(
    private identityFacade: IdentityFacade,
    private authService: AuthService,
    private companyService: BackofficeCompanyService

  ) { }

  changeCompany(companyId: string): Observable<BackofficeCompanyDetailDTO> {
    return this.authService.changeCompany(companyId).pipe(
      tap((user) => {
        localStorage.setItem(localStorageUser, JSON.stringify(user.token));

      }),
      switchMap((user) => {
        return this.setCurrentCompany(companyId).pipe(
          (tap(() => this.identityFacade.setCurrentUser(user)))
        );
      })
    );
  }

  private setCurrentCompany(companyId: string): Observable<BackofficeCompanyDetailDTO> {
    return this.companyService.getCompany(companyId).pipe((
      tap((company) => {
        this.identityFacade.setCurrentCompany(company)
      })
    ));
  }
  //FIXME: needs to be loaded from API!
  loadUserIntoIdentity(): Observable<UserDTO | undefined> {
    return this.authService.getUserInfo().pipe(
      tap((user) => {
        this.identityFacade.setCurrentUser(user);
      })
    );
  }

  loginUser(data: UserLogin): Observable<UserDTO | undefined> {
    return this.authService.loginUser(data).pipe(
      switchMap((user) => {
        if(user) {
          localStorage.setItem(localStorageUser, JSON.stringify(user.token));
        }
        return this.loadUserIntoIdentity();
      }),
    );
  }

  logoutUser(): Observable<undefined> {
    return this.authService.logoutUser().pipe(
      finalize(() => {
        // TODO: nemela by se mazat company? kdyz uz mazem usera
        this.loadUserIntoIdentity();
      })
    );
  }

  // registerUser(data: UserRegister): Observable<undefined> {
  //   return this.authService.registerUser(data);
  // }

  resetPassword(data: any): Observable<any> {
    return this.authService.resetPassword(data);
  }

  newPassword(data: any): Observable<any> {
    return this.authService.newPassword(data);
  }

  checkToken(data: any): Observable<any> {
    return this.authService.checkToken(data);
  }

  activateAccount(data: any): Observable<any> {
    return this.authService.activateAccount(data);
  }

  registration(data: any): Observable<any> {
    return this.authService.registration(data);
  }
  // checkToken(token: string | null): Observable<undefined> {
  //   const validateToken = token ? token : 'null';
  //   return this.authService.checkToken(validateToken);
  // }

  // resetPassword(data: UserResetPassword): Observable<undefined> {
  //   return this.authService.resetPassword(data);
  // }

  // activateUser(token: string): Observable<undefined> {
  //   return this.authService.confirmEmail(token);
  // }
}
