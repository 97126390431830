import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { cs_CZ, NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslatePipe, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import cs from '@angular/common/locales/cs';
import { initIdentityFactory } from '@domains/identity/domain/application-services/identity-init.factory';
import { AuthorizationFacade } from '@domains/identity/domain/application-services/authorization.facade';
import { NgxPermissionsModule } from 'ngx-permissions';
import { PageLoaderComponent } from '@domains/shared/feature/components/page-loader/page-loader.component';
import { BpCollapseComponent } from '@domains/shared/feature/components/bp-collapse/bp-collapse.component';
import { ErrorInterceptor } from '@domains/identity/domain/interceptor/error-interceptor.interceptor';
import { TokenInterceptor } from '@domains/identity/domain/interceptor/token-interceptor.interceptor';
import { Router } from '@angular/router';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';

registerLocaleData(cs);

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BpCollapseComponent,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    PageLoaderComponent,
    NzMessageModule,
    NgxPermissionsModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'cs',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    TranslatePipe,
    { provide: NZ_I18N, useValue: cs_CZ },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      deps: [NzMessageService, Router],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initIdentityFactory,
      deps: [AuthorizationFacade],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
