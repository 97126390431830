/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, switchMap, take } from 'rxjs';
import { IdentityFacade } from '../application-services/identity.facade';
import { localStorageUser } from '@domains/shared/domain/utils/global-constants';
import { UserDTO } from '../models/users.dto';


// Note: these are only the initial imports. Add more as needed.

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(public identityFacade: IdentityFacade) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = getToken();
    if (token && !request.url.includes('login')) {
      request = request.clone({
        setHeaders: {Authorization: `Bearer ${token}`}
      });
    }
    return next.handle(request);
  }
}

function getToken(): string | undefined {
  let token = undefined;
  const rawJSON = localStorage.getItem(localStorageUser);
  if (rawJSON !== null) {
    token = JSON.parse(rawJSON);
  }
  return token;
}
