import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
// import { EntityList } from '@shared/model/api-get-interface';
import { CompanyPreviewDTO } from '../../models/company-preview-dto.interface';
import { UsersPreviewDTO } from '../../models/users-preview.dto';
import { UserDTO } from '../../models/users.dto';
import { IdentityFacade } from '../../application-services/identity.facade';
import { webApiUrl } from '@domains/shared/domain/utils/url';
import { headers, localStorageUser } from '@domains/shared/domain/utils/global-constants';
import { BackofficeCompanyDetailDTO } from '../../models/company-backoffice-detail.interface';
import { CompanyCourseModel } from '../../models/company-course.model';
import { UserFormModel } from '../../models/user-form.model';
import { BackofficeCompanyCourseDTO } from '@domains/course/domain/models/company-course.interface';


@Injectable({ providedIn: 'root' })
export class BackofficeCompanyCourseService {

  private baseUrl = `${webApiUrl}/backoffice/CompanyCourse`;

  constructor(
    private http: HttpClient,
    private identityFacade: IdentityFacade) {
  }

  updateCompanyCourse(companyCourse: BackofficeCompanyCourseDTO): Observable<BackofficeCompanyCourseDTO> {
    const url = `${this.baseUrl}/${companyCourse.id}`;

    return this.http.put<BackofficeCompanyCourseDTO>(url, companyCourse, { headers });
  }

  getCompanyCourse(courseId: string): Observable<BackofficeCompanyCourseDTO> {
    const url = `${this.baseUrl}/${courseId}`;
    return this.http.get<BackofficeCompanyCourseDTO>(url, { headers });
  }

  // TODO: StudentModel
  addUser(companyCourseId: string, userId: string): Observable<any> {
    const url = `${this.baseUrl}/${companyCourseId}/student`;
    // TODO: StudentModel
    return this.http.post<any>(url, { userId }, { headers });
  }


}
