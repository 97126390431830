import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PageLoaderService } from '@domains/shared/domain/application-service/page-loader.service';
import { Observable } from 'rxjs';
import { PageLoaderOverlayComponent } from '../page-loader-overlay/page-loader-overlay.component';

@Component({
  selector: 'app-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.scss'],
  standalone: true,
  imports: [PageLoaderOverlayComponent, CommonModule]
})
export class PageLoaderComponent {

  isLoaderEnabled$!: Observable<boolean>;

  constructor(private pageLoaderService: PageLoaderService) {
    this.isLoaderEnabled$ = this.pageLoaderService.pageLoaderStatus$;
  }



}
