import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageLoaderService {

  private pageLoaderStatusSubject = new BehaviorSubject<boolean>(false);

  get pageLoaderStatus$(): Observable<boolean> {
    return this.pageLoaderStatusSubject.asObservable();
  }

  constructor() { }

  enablePageLoader(): void {
    this.pageLoaderStatusSubject.next(true);
  }

  disablePageLoader(): void {
    this.pageLoaderStatusSubject.next(false);
  }
}
