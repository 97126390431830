import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { webApiUrl } from '@domains/shared/domain/utils/url';
import { headers } from '@domains/shared/domain/utils/global-constants';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class BackofficeStudentTestResultService {

  // baseUrl = `${webApiUrl}/student/StudentTestResult`;

  constructor(private http: HttpClient) { }

  getCertificateBackoffice(studentTestResultId: string): Observable<Blob> {
    return this.http.get(`${webApiUrl}/backoffice/StudentTestResult/${studentTestResultId}/certificate`,
      { headers, responseType: 'blob' });
  }

}
