import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StudentCompanyCourseArticleService } from '../infrastructure/student/student-company-course-article.service';
import { StudentCompanyCourseService } from '../infrastructure/student/student-company-course.service';
import { StudentCompanyCourseDetailDTO } from '../models/student/student-company-course-detail.interface';
import { StudentCompanyCourseDTO } from '../models/student/student-company-course.interface';
import { StudentCourseArticleCreateAnswerDTO } from '../models/student/student-course-article-create-answer.interface';
import { StudentCourseArticleCreateFeedbackDTO } from '../models/student/student-course-article-create-feedback.interface';
import { StudentCourseArticleDTO } from '../models/student/student-course-article.interface';
import { StudentCourseTreeDTO } from '../models/student/student-course-tree.interface';

@Injectable({ providedIn: 'root' })
export class StudentCompanyCourseFacade {
  constructor(
     private studentCompanyCourseService: StudentCompanyCourseService,
     private studentCourseArticleService: StudentCompanyCourseArticleService,
  ) { }

  getCourses(): Observable<StudentCompanyCourseDTO[]> {
    return this.studentCompanyCourseService.getCourses();
  }

  getCourse(courseId: string): Observable<StudentCompanyCourseDetailDTO> {
    return this.studentCompanyCourseService.getCourse(courseId);
  }

  getCourseTree(courseId: string): Observable<StudentCourseTreeDTO> {
    return this.studentCompanyCourseService.getCourseTree(courseId);
  }

  getArticle(articleId: string): Observable<StudentCourseArticleDTO> {
    return this.studentCourseArticleService.getArticle(articleId);
  }

  createFeedback(articleId: string, data: StudentCourseArticleCreateFeedbackDTO): Observable<void> {
    return this.studentCourseArticleService.createFeedback(articleId, data);
  }

  createStatistics(articleId: string): Observable<void> {
    return this.studentCourseArticleService.createStatistics(articleId);
  }

  createAnswers(articleId: string, data: StudentCourseArticleCreateAnswerDTO[]): Observable<void> {
    return this.studentCourseArticleService.createAnswers(articleId, data);
  }

  clearAnswers(articleId: string): Observable<void> {
    return this.studentCourseArticleService.clearAnswers(articleId);
  }

  registerClickSection(articleSectionId: string): Observable<void> {
    return this.studentCourseArticleService.registerClickSection(articleSectionId);
  }

  // forgottenPassword(email: string): Observable<undefined> {
  //   return this.accountService.forgottenPassword(email);
  // }

  // checkToken(token: string | null): Observable<undefined> {
  //   const validateToken = token ? token : 'null';
  //   return this.accountService.checkToken(validateToken);
  // }

  // resetPassword(data: UserResetPassword): Observable<undefined> {
  //   return this.accountService.resetPassword(data);
  // }

  // activateUser(token: string): Observable<undefined> {
  //   return this.accountService.confirmEmail(token);
  // }
}
