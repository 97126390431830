import { HttpHeaders } from "@angular/common/http";
import { UserDTO } from "@domains/identity/domain/models/users.dto";

export const REDIRECTPAGE = {
    LOGIN_PAGE: '/auth/login',
}
//FIXME: temporary
export const localStorageUser = 'token';
export const headers: HttpHeaders = new HttpHeaders({
  'content-type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Accept-Language': 'cs',
});

export const editorConfig = {

  mediaEmbed: {
    previewsInData: true,
  },
  simpleUpload: {
    uploadUrl: '/api/v1/Shared/UploadInEditor',
  },
  removePlugins: ["MediaEmbedToolbar"],
  htmlSupport: {
    allow: [
      // Enables plain <div> elements.
      {
        name: 'div',
      },

      {
        name: 'nz-tooltip',
      },

      {
        name: 'nz-collapse',
      },

      {
        name: 'nz-collapse-panel',
      },

      {
        name: 'nz-tag',
      },

      // Enables plain <div>, <section> and <article> elements.
      {
        name: /^(div|section|article)$/,
      },

      // Enables <div>s with all inline styles (but no other attributes).
      {
        name: 'div',
        styles: true,
      },

      {
        name: 'img',
        styles: true,
        classes: true,
      },

      // Enables <div>s with foo and bar classes.
      {
        name: 'div',
        classes: true,
      },

      {
        name: 'section',
        classes: true,
      },


      // Adds support for `foo` and `bar` classes to the already supported
      // <p> elements (those are enabled by the dedicated paragraph feature).
      {
        name: 'p',
        classes: ['foo', 'bar'],
      },

      { 
        name: 'a',
        attributes: {
          target: '_blank',
        }
      },

      // Enables <div>s with foo="true" attribute and bar attribute that
      // can accept any value (boolean `true` value works as an asterisk).
      {
        name: 'div',
        attributes: {
          foo: 'true',
          bar: true,
        },
      },

      // Adds support for style="color: *" to the already supported
      // <p> and <h2-h4> elements.
      {
        name: "/^(p|h[2-4])$/'",
        styles: { color: true },
      },
    ],
  },
};
