import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, filter, forkJoin, map, Observable, switchMap, tap } from 'rxjs';
// import { EntityList } from '@shared/model/api-get-interface';
import { BackofficeCompanyService } from '../infrastructure/backoffice/backoffice-company.service';
import { HttpParams } from '@angular/common/http';
import { InviteService } from '../infrastructure/invite.service';
import { Invitation } from '../models/invitation.interface';
import { CompanyPreviewDTO } from '../models/company-preview-dto.interface';
import { UsersPreviewDTO } from '../models/users-preview.dto';
import { UserDTO } from '../models/users.dto';
import { CreateInvitation } from '../models/create-invitation.dto';
import { IdentityFacade } from './identity.facade';
import { BackofficeCompanyDetailDTO } from '../models/company-backoffice-detail.interface';
import { CompanyCourseModel } from '../models/company-course.model';
import { UserFormModel } from '../models/user-form.model';
import { BackofficeUserService } from '../infrastructure/backoffice/backoffice-user.service';
import { UserDetailBackofficeModel } from '../models/user-detail-backoffice.model';
import { BackofficeCompanyCourseDTO } from '@domains/course/domain/models/company-course.interface';
import { BackofficeCompanyCourseService } from '../infrastructure/backoffice/backoffice-company-course.service';
import { BackofficeStudentService } from '../infrastructure/backoffice/backoffice-student.service';
import { BackofficeStudentTestResultService } from '../infrastructure/backoffice/backoffice-student-test-result.service';
import { UserRoleEnum } from '../models/enums/user-role.enum';

@Injectable({ providedIn: 'root' })
export class BackofficeCompanyFacade {

  // private getCompanyUsersSubject = new BehaviorSubject<EntityList<UsersPreviewDTO>>({ list: [] });

  // public getCompanyUsers$ = this.getCompanyUsersSubject.asObservable();

  // private getInvitationsSubject = new BehaviorSubject<EntityList<Invitation>>({ list: [] });

  // public getInvitations$ = this.getInvitationsSubject.asObservable();

  // private getCompaniesSubject = new BehaviorSubject<EntityList<CompanyPreviewDTO>>({ list: [] });

  // public getCompanies$ = this.getCompaniesSubject.asObservable();

  constructor(
    private identityFacade: IdentityFacade,
    private companyService: BackofficeCompanyService,
    private companyCourseService: BackofficeCompanyCourseService,
    private userService: BackofficeUserService,
    private invitationService: InviteService,
    private backofficeStudentService: BackofficeStudentService,
    private studentTestResultService: BackofficeStudentTestResultService,
  ) { }

  loadCompanyInfoIntoIdentity(id: string): Observable<BackofficeCompanyDetailDTO> {
    return this.companyService.getCompany(id).pipe(
      tap((company) => {
        this.identityFacade.setCurrentCompany(company);
      })
    );
  }


  setCurrentCompany(companyId: string): void {
    this.getCompany(companyId).pipe((
      tap((company) => {
        this.identityFacade.setCurrentCompany(company)
      })
    )).subscribe();
  }

  getCompany(id: string): Observable<BackofficeCompanyDetailDTO> {
    return this.companyService.getCompany(id);
  }

  // switchCompany(id: string): Observable<UserInfoDTO> {
  //   return this.companyService.switchCompany(id);
  // }

  inviteUser(data: CreateInvitation): Observable<undefined> {
    return this.invitationService.inviteUser(data);
  }

  checkUser(token: string | null): Observable<Invitation> {
    const validToken = token ? encodeURIComponent(token) : 'null';
    return this.invitationService.checkUser(validToken);
  }

  // TODO: StudentModel
  submitStudentsIntoCourse(companyCourseId: string, userId: string): Observable<any> {
    return this.companyCourseService.addUser(companyCourseId, userId);
  }

  // acceptAllInvitations(): Observable<undefined> {
  //   return this.invitationService.acceptAll();
  // }

  // acceptInvitation(inviteId: string): Observable<EntityList<Invitation>>{
  //   return this.invitationService.acceptInvitation(inviteId)
  //   .pipe(switchMap(() => this.getInvitations()), tap((invitations) => this.getInvitationsSubject.next(invitations)));
  // }

  // rejectInvitation(inviteId: string): Observable<EntityList<Invitation>>{
  //   return this.invitationService.rejectInvitation(inviteId)
  //   .pipe(switchMap(() => this.getInvitations()), tap((invitations) => this.getInvitationsSubject.next(invitations)));;
  // }

  // deleteInvitation(inviteId: string): Observable<void>{
  //   return this.invitationService.deleteInvitation(inviteId);
  // }

  // getInvitations(): Observable<EntityList<Invitation>> {
  //   return this.invitationService.getInvitations();
  // }

  // deleteAccount(id: string): Observable<undefined> {
  //   return this.companyService.deleteAccount(id);
  // }

  getCompanyCourses(companyId: string): Observable<BackofficeCompanyCourseDTO[]> {
    return this.companyService.getCompanyCourses(companyId);
  }


  updateCompany(company: BackofficeCompanyDetailDTO): Observable<BackofficeCompanyDetailDTO> {
    return this.companyService.updateCompany(company);
  }

  getUsers(companyId: string): Observable<UserDTO[]> {
    return this.companyService.getUsers(companyId);
  }

  getUser(userId: string): Observable<UserDetailBackofficeModel> {
    return this.userService.getUser(userId);
  }

  createUser(companyId: string, user: UserFormModel): Observable<UserDTO> {
    return this.companyService.createUser(companyId, user);
  }

  createUsers(companyId: string, users: UserFormModel[]): Observable<UserDTO[]> {
    return this.companyService.createUsers(companyId, users);
  }

  patchUser(entity: any, id: string): Observable<any> {
    return this.userService.patch(entity, id);
  }

  sendRegisterEmail(userId: string): Observable<any> {
    return this.userService.sendRegisterEmail(userId);
  }

  // CompanyCourse
  updateCompanyCourseEmail(course: BackofficeCompanyCourseDTO) {
    return this.companyService.updateEmail(course);
  }

  updateCompanyCourse(companyCourse: BackofficeCompanyCourseDTO): Observable<BackofficeCompanyCourseDTO> {
    return this.companyCourseService.updateCompanyCourse(companyCourse);
  }

  getCurrentCompanyStudents(): Observable<UserDTO[]> {
    return this.identityFacade.getCurrentCompany$.pipe(
      map(c => c?.users!.filter(user => user.assignedRoles.some(r => r.name === UserRoleEnum.Student))!,
      ));
  }

  getCompanyCourse(courseId: string): Observable<BackofficeCompanyCourseDTO> {
    return this.companyCourseService.getCompanyCourse(courseId);
  }

  getCertificateBackoffice(studentTestResultId: string): Observable<BlobPart> {
    return this.studentTestResultService.getCertificateBackoffice(studentTestResultId);
  }
  //TODO: model StudentModel
  updateStudentCourse(course: any): Observable<any> {
    return this.backofficeStudentService.update(course);
  }
}
